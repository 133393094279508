import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Posts from '../components/Posts'
import Projects from '../components/Projects'
import SEO from '../components/SEO'
import Blurb from '../components/Blurb'
import SimpleListing from '../components/SimpleListing'

import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

import projects from '../data/projects'
import interviews from '../data/interviews'
import speaking from '../data/speaking'
import featuredCertifications from '../data/featuredCertifications'
import certifications from '../data/certifications'

export default function BlogIndex() {

  const Section = ({ title, children, button, ...props }) => (
    <section {...props}>
      <h2>
        {title}
        {button && (
          <Link className="section-button" to="/blog">
            View all
          </Link>
        )}
      </h2>
      {children}
    </section>
  )


  return (
    <Layout>
      <SEO customDescription="Articles, tutorials, snippets, musings, and everything else." />
      <header>
        <div className="container">
          <h1>Licenses and Certifications</h1>
          <p className="subtitle">
            list of certificates i've achieved so far
          </p>
        </div>
      </header>
      <Helmet title={config.siteTitle} />
      <SEO />
      <div className="container index">
        <Section title="">
          <SimpleListing data={certifications} />
        </Section>
      </div>
    </Layout>
  )
}